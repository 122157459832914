import axios from "@/axios/globalaxios";
import {findGroupRole, updGroupRole} from "@/views/megagame/participants-user/groupApi";
import {userInfo} from "@/views/megagame/methods";
import {ElMessage} from "element-plus";
import {getUserLists} from "@/views/megagame/participants-user/gropMethods";

export const api = data =>{
    return axios({
        url: "../mock.json",
        method: 'post',
        data: data
    })
}

// 大赛主页面
//教师
export const competitionList = data =>{ //查询大赛
    return axios({
        method: 'post',
        url: '/tradelab/competition/competitionList',
        data: data
    })
}

export const findGroupByCompetitionIdAndUserId = data =>{ //查询是否存在小组
    return axios({
        method: 'post',
        url: '/tradelab/competition/findGroupByCompetitionIdAndUserId',
        data: data
    })
}
export const findCompetitionById = data =>{ //单条大赛详情
    return axios({
        method: 'post',
        url: '/tradelab/competition/findCompetitionById',
        data: data
    })
}
//学生
export const getUserCompetition = data =>{ //我的大赛
    return axios({
        method: 'post',
        url: '/tradelab/competition/getUserCompetition',
        data: data
    })
}
export const addCompetitionGroup = data =>{ //创建小组
    return axios({
        method: 'post',
        url: '/tradelab/competition/addCompetitionGroup',
        data: data
    })
}
export const getCompetitionGroupList = data =>{ //查看大赛小组
    return axios({
        method: 'post',
        url: '/tradelab/competition/getCompetitionGroupList',
        data: data
    })
}
export const joinCompetition = data =>{ //加入大赛
    return axios({
        method: 'post',
        url: '/tradelab/competition/joinCompetition',
        data: data
    })
}

export const addGroupUser = data =>{ //加入大赛小组
    return axios({
        method: 'post',
        url: '/tradelab/competition/addGroupUser',
        data: data
    })
}

// 创建大赛
export const addCompetition = data =>{ //新增大赛
    return axios({
        method: 'post',
        url: '/tradelab/competition/addCompetition',
        data: data
    })
}
export const update = data =>{ //修改单条大赛
    return axios({
        method: 'post',
        url: '/tradelab/competition/update',
        data: data
    })
}
export const findCourseType = data =>{ //查找交易品种
    return axios({
        method: 'post',
        url: '/tradelab/course/findCourseType',
        data: data
    })
}



// 参赛人群
export const getUserByCompetition = data =>{ //根据大赛进行查询学生
    return axios({
        method: 'post',
        url: '/tradelab/sysUser/getUserByCompetition',
        data: data
    })
}

// 参赛小组
export const getUserByCompetitionGroup = data =>{ //根据大赛进行查询小组
    return axios({
        method: 'post',
        url: '/tradelab/competition/findGroup',
        data: data
    })
}

//大赛排名
export const findRanKing = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/competition/findRanKing',
        data: data
    })
}
export const exportRanking = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/competition/exportRanking',
        data: data,
        responseType: 'blob',
    })
}


//关键比率
export const findKeyRatios = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/competition/findKeyRatios',
        data: data
    })
}
export const exportKeyRatios = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/competition/exportKeyRatios',
        data: data,
        responseType: 'blob',
    })
}

//资产配置
export const findAssetAllocation = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/competition/findAssetAllocation',
        data: data
    })
}
export const exportAssetAllocation = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/competition/exportAssetAllocation',
        data: data,
        responseType: 'blob',
    })
}

//风险分析
export const findRiskAnalysis = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/competition/findRiskAnalysis',
        data: data
    })
}
export const exportRiskAnalysis = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/competition/exportRiskAnalysis',
        data: data,
        responseType: 'blob',
    })
}

//重仓证券
export const findHeavyweightSecurities = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/competition/findHeavyweightSecurities',
        data: data
    })
}
export const exportHeavyweightSecurities = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/competition/exportHeavyweightSecurities',
        data: data,
        responseType: 'blob',
    })
}

//行业配置
export const findIndustryAllocation = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/competition/findIndustryAllocation',
        data: data
    })
}
export const exportIndustryAllocation = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/competition/exportIndustryAllocation',
        data: data,
        responseType: 'blob',
    })
}

//历史回报
export const findNetReturnGrowthRate = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/competition/findNetReturnGrowthRate',
        data: data
    })
}

//删除大赛
export const deleteSingleUser = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/competition/deleteSingleUser',
        data: data
    })
}
export const exportNetReturnGrowthRate = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/competition/exportNetReturnGrowthRate',
        data: data,
        responseType: 'blob',
    })
}
export const findUserByGroupId = data =>{
    return axios({
        url: "/tradelab/competition/findGroupUser",
        method: 'post',
        data:data
    })
}
export const deleteGroupUser = data =>{
    return axios({
        url: "/tradelab/competition/deleteGroupUser",
        method: 'post',
        data:data
    })
}
export const getUserTypes = (data) =>{
    findGroupRole().then(res=>{
        if (res.data.code === '200'){
            data.nowJobOptions = res.data.data.memberRoles
        }
    })
}

export const getAcidByclassID = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/transaction/getAcidByclassID',
        data: data
    })
}

export const getCompGroupId = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/competition/getCompGroupId',
        data: data
    })
}
