import axios from '@/axios/globalaxios'

export const findUserLogByGroupId = data =>{
    return axios({
        url: "/tradelab/log/selectChangeAuthorityLog",
        method: 'post',
        data:data
    })
}
// 移除用户
export const delGroupUser = data =>{
    return axios({
        url: "/tradelab/competition/deleteGroupUser",
        method: 'post',
        data:data
    })
}
// 查询所有类型
export const findGroupRole = data =>{
    return axios({
        url: "/tradelab/courseGroup/findGroupRole",
        method: 'post',
        data:data
    })
}
// 修改小组权限
export const updGroupRole = data =>{
    return axios({
        url: "/tradelab/competition/updateGroupUserRole",
        method: 'post',
        data:data
    })
}
